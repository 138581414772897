import React, { Fragment, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import { postData } from "./workers";
import useIdentifiers from "./hooks/useIdentifiers";
import "./App.css";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
  margin: 0,
};

const buttonStyle = {
  padding: 10,
  borderRadius: 8,
  backgroundColor: "#3f3f3f",
  color: "#fefefe",
  border: "none",
  fontSize: "1.2rem",
  cursor: "pointer",
};

const Download = () => {
  const [downloadLink, setDownloadLink] = useState(null);

  useEffect(() => {
    const getLatestVersion = async () => {
      const updateUrl = process.env.REACT_APP_VERSION_FILE_URL;
      const {
        data: { url },
      } = await axios.get(updateUrl);
      setDownloadLink(url);
    };
    getLatestVersion();
  }, []);

  const { user_id, device } = useIdentifiers();

  useEffect(() => {
    const initialize = async () => {
      await Promise.all([
        postData({
          user_id,
          field: "devices",
          payload: [device],
          pk: "platform",
        }),
        postData({
          user_id,
          field: "download_page_accessed",
          payload: [{ timestamp: new Date().toISOString() }],
          pk: "timestamp",
        }),
      ]);
    };
    if (user_id && device) initialize();
  }, [user_id, device]);

  return (
    <div style={containerStyle}>
      {downloadLink ? (
        <Fragment>
          <img src="/icon.png" alt="emaad logo" />
          <a href={downloadLink} download>
            <button style={buttonStyle}>تحميل أحدث نسخة</button>
          </a>
        </Fragment>
      ) : (
        <Loader type="TailSpin" color="#3f3f3f" height={"25%"} width={"25%"} />
      )}
    </div>
  );
};

const Share = () => {
  const { post_id, sender_id } = useParams();
  const { user_id, device } = useIdentifiers();
  useEffect(() => {
    const postAndForward = async () => {
      await Promise.all([
        postData({
          user_id,
          field: "devices",
          payload: [device],
          pk: "platform",
        }),
        postData({
          user_id,
          field: "share_accessed",
          payload: [
            { post_id, sender_id, timestamp: new Date().toISOString() },
          ],
          pk: "timestamp",
        }),
      ]);
      window.location.replace(`https://emaad.net/${post_id}`);
    };
    if (user_id && device && post_id && sender_id) postAndForward();
  }, [user_id, device, post_id, sender_id]);
  return (
    <div style={containerStyle}>
      <Loader type="TailSpin" color="#3f3f3f" height={"25%"} width={"25%"} />
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/share/:sender_id/:post_id" component={Share} />
          <Route path="/" exact component={Download} />
          <Redirect to="/" exact />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
//https://theemaad.net/share/79173aab-097d-4f29-b864-866e39afab54/%D8%B1%D8%B3%D8%A7%D9%84%D8%A9-%D8%A5%D9%84%D9%89-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%B6%D8%B9%D9%81%D9%8A%D9%86-%D9%81%D9%8A-%D8%A8%D9%84%D8%A7%D8%AF-%D8%A7%D9%84%D8%BA%D8%B1%D8%A8-a-message-for-the-op
