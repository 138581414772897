import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
const Fingerprint2 = require("fingerprintjs2");

const getMurmur = async () => await localStorage.getItem("murmur");
const setMurmur = async (user_id) =>
  await localStorage.setItem("murmur", user_id);

const useIdentifiers = () => {
  const [device, setDevice] = useState();
  const [user_id, setUserId] = useState("");

  useEffect(() => {
    const determineMurmur = async () => {
      const user_id = await getMurmur();
      if (user_id) setUserId(user_id);

      const options = { excludes: { canvas: true, webgl: true } };
      Fingerprint2.get(options, (components) => {
        const componentsToFilter = []; // TO ADD ABILITY TO FILTER COMPONENTS BEFORE FINGERPRINT
        const values = components
          .filter((component) => !componentsToFilter.includes(component.key))
          .map((component) => ({
            [component.key]: component.value,
          }))
          .reduce((a, b) => ({ ...a, ...b }), {});
        const murmur = uuidv4();

        setDevice(values);
        if (!user_id) {
          setUserId(murmur);
        }
      });
    };

    if (window.requestIdleCallback) {
      requestIdleCallback(determineMurmur);
    } else {
      setTimeout(determineMurmur, 500);
    }
  }, []);

  useEffect(() => {
    const persistUUID = async () => await setMurmur(user_id);
    if (user_id) persistUUID();
  }, [user_id]);

  return { device, user_id };
};

export default useIdentifiers;
